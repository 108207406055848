import TabulatorController from "./tabulator_controller";

export default class RemoteTabulatorController extends TabulatorController {
    static readonly DEFAULT_REMOTE_PARAMS = {
        pagination:true,
        paginationMode:"remote",
        filterMode:"remote",
        sortMode:"remote"
    }

    protected default_params(): Object {
        return {
            ...super.default_params(),
            ...RemoteTabulatorController.DEFAULT_REMOTE_PARAMS
        }
    }
}