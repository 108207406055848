import { Controller } from "@hotwired/stimulus"

export default class StreamEventController extends Controller {
    static values = {
        eventType: String
    }

    declare eventTypeValue: string

    initialize(): void {
        window.dispatchEvent(new CustomEvent("stream-event:" + this.eventTypeValue))
    }
}