import { Controller } from "@hotwired/stimulus"

export default class MvcTabBarController extends Controller {
    static values = {
        connectTo: String
    }

    connectTargets: Array<Element> = []
    declare connectToValue: string

    initialize(): void {
        document.querySelectorAll(this.connectToValue).forEach((el) => {
            this.connectTargets.push(el)
        })

        this.element.addEventListener("MDCTabBar:activated", this.handleTabBarActivated.bind(this))

        // @ts-ignore
        this.element.children[0].click();
    }

    private handleTabBarActivated(e: CustomEvent<{index:number}>): void {
        for (const el of this.connectTargets) {
            let idx = 0
            for (const tabElement of Array.from(el.children)) {
                if (idx === e.detail.index) {
                    (tabElement as unknown as HTMLElement).hidden = false
                    tabElement.dispatchEvent(new Event('activated'))
                } else {
                    (tabElement as unknown as HTMLElement).hidden = true
                }
                idx++
            }
        }
    }
}