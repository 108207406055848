import { Controller } from "@hotwired/stimulus"

export default class MwcSelectController extends Controller {
    static readonly INPUT_TAG_NAME = 'MWC-TEXTFIELD'
    static readonly INPUT_TEMPLATE = `<${MwcSelectController.INPUT_TAG_NAME} icon="search" autocomplete="off" type="text" style="width: 100%"/>`

    static values = {
        typeahead: Boolean
    }

    // @ts-ignore
    typeaheadInputEl: HTMLInputElement

    initialize(): void {
        //BUG: https://github.com/material-components/material-web/issues/1493
        // @ts-ignore
        this.element.layoutOptions().then(()=> {
            if (this.isTypehead()) {
                this.initializeTypeahead()
            }
        })

        // this.element.addEventListener('closed', () => {
        //     // @ts-ignore
        //     this.element.layout()
        // })
    }

    private isTypehead(): boolean {
        // @ts-ignore
        return this.hasTypeaheadValue && this.typeaheadValue
    }

    private isAllowInput(): boolean {
        // @ts-ignore
        return this.hasAllowInputValue && this.allowInputValue
    }

    private static createInputElement(): HTMLInputElement {
        const templateEl = document.createElement('template')
        templateEl.innerHTML = MwcSelectController.INPUT_TEMPLATE

        return templateEl.content.firstElementChild as HTMLInputElement
    }

    private initializeTypeahead(): void {
        this.typeaheadInputEl = MwcSelectController.createInputElement()

        // TYPEAHEAD INPUT NOT WORKING PROPERLY WITHOUT THIS HOOKS
        // TYPEAHED NOT INPUT
        this.element.addEventListener('keydown', (e: KeyboardEvent) => {
            e.stopPropagation()
        }, true)
        //TYPEAHEAD DOUBLE SUBMIT
        this.typeaheadInputEl.addEventListener('change', (e) => {
            e.stopPropagation()
        }, true)

        this.typeaheadInputEl.addEventListener('input', this.processInputKeydown.bind(this))
        this.element.addEventListener('opened', (e) => {
            this.typeaheadInputEl.focus()
        })

        // HOOK: WHEN START INPUT AT FIRST -> SELECT CLOSES
        // @ts-ignore
        // const menu = this.element.shadowRoot.querySelector('.mdc-select__menu')
        // if (menu) {
        //     menu.addEventListener('selected', (e) => {
        //         // @ts-ignore
        //         if (e.detail.index < 0 ) {
        //             e.preventDefault()
        //             e.stopPropagation()
        //         }
        //     }, true)
        // }

        this.element.prepend(this.typeaheadInputEl)
    }

    private processInputKeydown(e: InputEvent): void {
        this.filterOptions((e.target as HTMLInputElement).value)
    }

    private filterOptions(value: string): void {
        for (const child of Array.from(this.element.children)) {
            if (child.tagName != MwcSelectController.INPUT_TAG_NAME) {
                child.setAttribute('hidden', '')
            }
        }
        const filteredOptions = this.findMatches(value, Array.from(this.element.children))
        for (const filteredChild of filteredOptions) {
            if (filteredChild.tagName != MwcSelectController.INPUT_TAG_NAME) {
                filteredChild.removeAttribute('hidden')
            }
        }
    }

    private findMatches(value: string, arr: Array<Element>): Array<Element> {
        const regexp = new RegExp(value.toLowerCase())

        return arr.filter((el) => {
            if (value.length === 0) {
                return true
            } else {
                // @ts-ignore
                if (el.tagName != MwcSelectController.INPUT_TAG_NAME) {
                    // @ts-ignore
                    return regexp.test(el.text.toLowerCase())
                } else {
                    return true
                }
            }
        })
    }
}