import ViewController from "./view_controller";

export default class FormController extends ViewController {
    static targets = [ "focus" ]

    static values = {
        overlay: Boolean, default: false
    }

    declare focusTargets: Array<HTMLInputElement>
    declare overlayValue: boolean

    initialize(): void {
        for (const focusElement of this.focusTargets) {
            if (focusElement.tagName === 'MWC-TEXTFIELD') {

                // @ts-ignore
                focusElement.layout().then(() => {
                    focusElement.focus()
                })
            } else {
                focusElement.focus()
            }
        }
        if (this.overlayValue) {
            this.element.addEventListener("turbo:submit-start", async event => {
                await this.showOverlay()
                this.element.addEventListener('turbo:submit-end', event => {
                    this.hideOverlay()
                }, {once: true})
            })
        }
    }

    submit(e?: Event): void {
        (this.element as HTMLFormElement).requestSubmit()
    }

    reset(): void {
        (this.element as HTMLFormElement).reset()
        this.element.querySelectorAll('mwc-textfield').forEach((el) => {
            // @ts-ignore
            el.value = "";
        })
    }


    inputChanged(e: Event): void {
    }

    submitOnEnter(e: KeyboardEvent): void {
        if (e.key === 'Enter') {
            this.submit()
            e.preventDefault()
        }
    }
}