import ViewController from "./view_controller"

export default class extends ViewController {
    static values = {
        overlay: Boolean, default: false
    }

    declare overlayValue: boolean

    initialize(): void {
        if (this.overlayValue) {
            this.element.addEventListener('turbo:before-fetch-request', async (event) => {
                event.preventDefault()
                this.showOverlay()
                // @ts-ignore
                event.detail.resume()
            })
            this.element.addEventListener('turbo:before-fetch-response', () => {
                this.hideOverlay()
            })
        }
    }

    reload(): void {
        // @ts-ignore
        this.element.reload()
    }
}