import { Controller } from "@hotwired/stimulus"
const onScan = require('onscan.js')
import UIkit from 'uikit'

export default class InputController extends Controller {
    static targets = ['input']

    declare inputTarget: HTMLInputElement

    initialize(): void {
        if (!onScan.isAttachedTo(document)) onScan.attachTo(document, {suffixKeyCodes: [13]})
        document.addEventListener('scan', this.handleScanEvent.bind(this))
    }

    disconnect() {
        document.removeEventListener('scan', this.handleScanEvent.bind(this))
        super.disconnect();
    }

    private handleScanEvent(e: CustomEvent<{scanCode: string, qty: number}>): void {
        this.inputTarget.value = e.detail.scanCode
        const evt = new CustomEvent('input:scan')
        this.inputTarget.dispatchEvent(evt)
        this.inputTarget.value = ''
    }
}