import { Controller } from "@hotwired/stimulus"
import WebStorageES6 from "web-storage-es6"

export default class WebStorage extends Controller {
    static readonly DEFAULT_NAMESPACE = 'default'
    static readonly DEFAULT_STORAGE_TYPE = 'Session'

    static targets = ["element"]
    static values = {
        namespace: String,
        type: String
    }

    declare typeValue: string
    declare hasTypeValue: boolean
    declare namespaceValue: string
    declare hasNamespaceValue: boolean
    declare elementTargets: Array<Element>

    webStorageES6: WebStorageES6

    initialize(): void {
        this.webStorageES6 = new WebStorageES6(this.storageType, this.namespace)
    }

    elementTargetConnected(element: Element): void {
        const id = element.id
        if (id) {
            const data = this.webStorageES6.get(id)
            if (data) {
                for (let key in data) {
                    element[key] = data[key]
                }
            }
        }
    }

    storeElement(event: CustomEvent): void {

        // @ts-ignore
        const params: {store: string} = event.params
        const element = event.target as Element

        this._storeElement(element, params)
    }

    private _storeElement(element: Element, params: {store: string}): void {
        const data = {
        }

        data[params.store] = element[params.store]

        const id = element.id

        if (id) {
            this.webStorageES6.put(id, data)
        }
    }

    get namespace(): string {
        if (this.hasNamespaceValue) {
            return this.namespaceValue
        } else {
            return WebStorage.DEFAULT_NAMESPACE
        }
    }

    get storageType(): string {
        if (this.hasTypeValue) {
            return this.typeValue
        } else {
            return WebStorage.DEFAULT_STORAGE_TYPE
        }
    }
}