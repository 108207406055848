import ViewController from "./view_controller"

export default class extends ViewController {
    static values = {
        target: String
    }

    declare targetValue: string
    declare hasTargetValue: boolean

    target: Element

    initialize(): void {
        if (this.hasTargetValue) {
            this.target = document.querySelector(this.targetValue)
            if (this.target) {
                this.target.addEventListener('click', () => {
                    // @ts-ignore
                    this.element.open = !this.element.open
                })
            }
        }
    }

    remove(): void {
        this.element.remove()
    }
}