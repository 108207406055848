import ViewController from "./view_controller"

export default class ApplicationController extends ViewController {
    static targets = ['content']

    declare contentTarget: Element
    declare hasContentTarget: boolean

    initialize(): void {
        // document.documentElement.style.setProperty('--animate-duration', '.1s');
        if (this.hasContentTarget) {
            document.addEventListener('turbo:visit', (event) => {
                this.showOverlay(this.contentTarget)
            })
        }

        const heartbeatEl = document.getElementById('heartbeat')
        const heartBeatAnimation = 'animate__pulse'
        const clickAnimation = 'animate__tada'
        const busyAnimation = 'animate__heartBeat'
        if (heartbeatEl) {
            heartbeatEl.style.cursor = 'pointer'
            heartbeatEl.classList.add('animate__animated', 'animate__infinite', 'animate__slow', heartBeatAnimation)
            heartbeatEl.addEventListener('click', (e) => {
                heartbeatEl.classList.remove('animate__infinite', heartBeatAnimation)
                heartbeatEl.classList.add(clickAnimation)
                heartbeatEl.addEventListener('animationend', () => {
                    heartbeatEl.classList.remove(clickAnimation)
                    heartbeatEl.classList.add('animate__infinite', heartBeatAnimation)
                }, {once: true})
            })
        }

        const origFetch = window.fetch;

        window.fetch = async ( ...args) => {
            if (heartbeatEl) {
                heartbeatEl.classList.remove(heartBeatAnimation)
                heartbeatEl.classList.add(busyAnimation)
            }
            return await origFetch(...args).then(success => {
                heartbeatEl.classList.remove(busyAnimation)
                heartbeatEl.classList.add(heartBeatAnimation)
                return Promise.resolve(success)
            }).catch(reason => {
                this.fail(reason)
                return Promise.reject(reason)
            })
        }
    }

    fail(message: string): Promise<void> {
        return new Promise<void>(resolve => {
            this.notify(message)
            const heartbeatEl = document.getElementById('heartbeat')
            if (heartbeatEl) {
                heartbeatEl.addEventListener('animationend', () => {
                    heartbeatEl.remove()
                    resolve()
                }, {once: true})
                heartbeatEl.classList.remove('animate__infinite')
                heartbeatEl.classList.add('animate__hinge')
            } else {
                resolve()
            }
        })
    }

    notify(text: string): void {
        const messageEl = document.createElement('mwc-snackbar')
        messageEl.setAttribute('data-controller', 'mwc-snackbar')
        messageEl.setAttribute('labelText', text)

        const iconEl = document.createElement('mwc-icon-button')
        iconEl.setAttribute('icon', 'close')
        iconEl.setAttribute('slot', 'dismiss')

        messageEl.appendChild(iconEl)

        this.element.appendChild(messageEl)
    }

    confirm(event: CustomEvent): void {
        // @ts-ignore
        if (!window.confirm(event.params.message)) {
            event.preventDefault()
        }
    }
}