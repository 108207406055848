import ViewController from "./view_controller";
import PrintJs from 'print-js'

export default class extends ViewController {
    static targets = ["printable"]
    static values = {
        css: String
    }

    declare cssValue: string
    declare printableTarget: Element

    initialize(): void {
        if (!this.printableTarget.id) {
            this.printableTarget.id = this.randomId()
        }
    }

    print(e?: CustomEvent<{action: string}>): void {
        if (e) {
            if (e.detail.action === 'print') {
                this._print()
            }
        }
    }

    private _print(): void {
        PrintJs({
            printable: this.printableTarget.id,
            type: 'html',
            css: this.cssValue,
            scanStyles: false
        })
    }
}