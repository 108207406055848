import { Controller } from "@hotwired/stimulus"

export default class ViewController extends Controller {
    hide(element?: Element): Promise<void> {
        if (!element) {
            element = this.element
        }
        return new Promise<void>(resolve => {
            element.addEventListener('animationend', () => {
                element.classList.remove("animate__animated", "animate__fadeOut")
                // @ts-ignore
                element.hidden = true
                resolve()
            }, {once: true})
            element.classList.add("animate__animated", "animate__fadeOut")
        })
    }

    randomId(): string {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    reloadFrame({params: {id}}): void {
        const frameEl = document.getElementById(id)
        if (frameEl) {
            // @ts-ignore
            frameEl.reload()
        }
    }

    show(element?: Element): Promise<void> {
        if (!element) {
            element = this.element
        }
        return new Promise<void>(resolve => {
            element.addEventListener('animationend', () => {
                element.classList.remove("animate__animated", "animate__fadeIn")
                resolve()
            }, {once: true})
            element.classList.add("animate__animated", "animate__fadeIn")
            // @ts-ignore
            element.hidden = false
        })
    }

    showOverlay(element?: Element): Promise<void> {
        if (!element) {
            element = this.element
        }
        return new Promise<void>(resolve => {
            const overlayEl = ViewController.createOverlayElement()
            overlayEl.addEventListener('animationend', () => {
                resolve()
            }, {once: true})

            element.appendChild(overlayEl)
        })
    }

    hideOverlay(element?: Element): Promise<void> {
        if (!element) {
            element = this.element
        }

        return new Promise<void>(resolve => {
            element.querySelectorAll('.uk-overlay').forEach(overlayElement => {
                overlayElement.remove()
            })
            resolve()
        })
    }

    private static createOverlayElement(): Element {
        const overlayEl = document.createElement('div')
        overlayEl.classList.add('uk-position-cover',
            'uk-overlay',
            'uk-overlay-default',
            'uk-flex',
            'uk-flex-center',
            'uk-flex-middle',
            'animate__animated',
            'animate__fadeIn',
            'animate__faster'
        )
        overlayEl.style.zIndex = '999'

        const progressEl = document.createElement('mwc-circular-progress-four-color')
        progressEl.setAttribute('indeterminate', '')
        progressEl.setAttribute('density', '5')

        overlayEl.appendChild(progressEl)

        return overlayEl
    }
}