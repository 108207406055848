import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        anchor: String
    }

    anchorElement: Element

    declare hasAnchorValue: boolean
    declare anchorValue: string

    initialize(): void {
        this.anchorElement = this.findAnchorElement()
        if (this.anchorElement) {
            this.anchorElement.addEventListener('click', this.handleAnchorClickEvent.bind(this))
            // @ts-ignore
            this.element.anchor = this.anchorElement
        }
    }

    private findAnchorElement(): Element | undefined {
        if (this.hasAnchorValue) {
            return document.querySelector(this.anchorValue)
        }

        return this.element.previousElementSibling
    }

    private handleAnchorClickEvent(e: Event): void {
        // @ts-ignore
        if (!this.element.open) {
            // @ts-ignore
            this.element.anchor = this.anchorElement
        }
        // @ts-ignore
        this.element.open = !this.element.open
        e.preventDefault()
    }
}