import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['header', 'transaction']

    declare headerTargets: Array<Element>
    declare transactionTarget: HTMLElement
    declare hasTransactionTarget: boolean

    headerTargetConnected(element: HTMLElement): void {
        if (this.hasTransactionTarget) {
            const transactionId = this.transactionTarget.dataset.transactionId
            if (transactionId) {
                const headerId = element.dataset.transactionId
                if (headerId) {
                    if (transactionId === headerId) {
                        element.setAttribute('white-blue', '')
                        element.removeAttribute('white')
                        element.classList.add('uk-animation-shake')
                    }
                }
            }
        }
    }

    transactionTargetConnected(element: HTMLElement): void {
        const transactionId = element.dataset.transactionId
        if (transactionId) {
            this.headerTargets.forEach((el: HTMLElement) => {
                const elTransactionId = el.dataset.transactionId
                if (elTransactionId) {
                    if (elTransactionId === transactionId) {
                        el.setAttribute('white-blue', '')
                        el.removeAttribute('white')
                        el.classList.add('uk-animation-shake')
                    } else {
                        el.removeAttribute('white-blue')
                        el.setAttribute('white', '')
                        el.classList.remove('uk-animation-shake')
                    }
                }
            })
        }

        const status = element.dataset.transactionStatus
        if (status) {
            let animationClass = 'tm-card-header-success'
            if (status === '422') {
                animationClass = 'tm-card-header-error'
            }
            const headerElement = element.querySelector('.tm-card-header')
            if (headerElement) {
                headerElement.classList.add(animationClass)
                setTimeout(() => {
                    headerElement.classList.remove(animationClass)
                }, 1)
            }
        }
    }
}