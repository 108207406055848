import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    initialize() {
        const styleEl = document.createElement('style');
        styleEl.innerHTML = `.mdc-snackbar__surface {
                              max-width: min-content !important;
                            };`

        this.element.shadowRoot.appendChild(styleEl);

        this.element.addEventListener('MDCSnackbar:closed', () => {
            this.element.remove()
        })

        // @ts-ignore
        this.element.show()
    }
}