import { Controller } from "@hotwired/stimulus"
// @ts-ignore
import {TabulatorFull as Tabulator} from 'tabulator-tables';

export default class TabulatorController extends Controller {
    static readonly DEFAULT_OPTIONS = {
        export: false
    }
    static readonly DEFAULT_PARAMS = {
        autoResize:true,
        responsiveLayout:"collapse",
        layout: "fitDataStretch",
        paginationSize:10,
        paginationSizeSelector:[10, 25, 50, 100, true],
        locale:true,
        langs: {
            "ru-ru": {
                "columns": {
                    "name": "Наименование",
                },
                "data": {
                    "loading": "Загрузка", //data loader text
                    "error": "Ошибка", //data error text
                },
                "groups": { //copy for the auto generated item count in group header
                    "item": "шт", //the singular  for item
                    "items": "шт", //the plural for items
                },
                "pagination": {
                    "page_size": "Размер страницы", //label for the page size select element
                    "page_title": "Отобразить страницу",//tooltip text for the numeric page button, appears in front of the page number (eg. "Show Page" will result in a tool tip of "Show Page 1" on the page 1 button)
                    "first": "Первая", //text for the first page button
                    "first_title": "Первая страница", //tooltip text for the first page button
                    "last": "Последняя",
                    "last_title": "Последняя страница",
                    "prev": "Предыдущая",
                    "prev_title": "Предыдущая страница",
                    "next": "Следующая",
                    "next_title": "Следующая страница",
                    "all": "Все",
                    "counter": {
                        "showing": "Показывается",
                        "of": "из",
                        "rows": "строк",
                        "pages": "страниц",
                    }
                },
                "headerFilters": {
                    "default": "фильтр ...", //default header filter placeholder text
                    "columns": {
                        "name": "фильтр ...", //replace default header filter text for column name
                    }
                }
            }
        }
    }

    static values = {
        params: Object,
        options: Object,
        columns: Array,
        data: Array
    }

    declare paramsValue: Object
    declare optionsValue: {export: boolean}
    declare dataValue: Array<Object>
    declare hasDataValue: boolean
    declare columnsValue: []

    tabulator: Tabulator

    initialize(): void {
        const options = {
            ...this.default_options(),
            ...this.optionsValue
        }
        const params = {
            ...this.default_params(),
            ...this.paramsValue,
            columns: this.columnsValue
        }

        if (this.hasDataValue) {
            params['data'] = this.dataValue
        }

        this.tabulator = new Tabulator(this.element, params)

        if (options.export) {
            const actionsContainer = document.createElement('DIV')
            actionsContainer.classList.add('uk-margin', 'uk-text-right', 'uk-position-relative')
            const buttonElement = document.createElement('MWC-BUTTON')
            buttonElement.setAttribute('label', 'Экспорт')
            buttonElement.setAttribute('icon', 'expand_more')
            buttonElement.setAttribute('trailingIcon', '')

            const menuElement = document.createElement('mwc-menu')
            menuElement.setAttribute('data-controller', 'mwc-menu')

            const XLSExportListItem = document.createElement('MWC-LIST-ITEM')
            const textEl = document.createElement('SPAN')
            textEl.innerText = 'Экспортировать в XLSX'
            const XLSButtonElement = document.createElement('A')
            XLSButtonElement.setAttribute('href', '#')
            XLSButtonElement.classList.add('tm-anchor-block-overlay')
            XLSExportListItem.append(textEl, XLSButtonElement)

            XLSButtonElement.addEventListener('click', (e) => {
                this.tabulator.download("xlsx", `${new Date().toLocaleDateString()}.xlsx`, {sheetName:"MAIN"});
                e.preventDefault()
                e.stopPropagation()
            })

            menuElement.append(XLSExportListItem)

            actionsContainer.append(buttonElement, menuElement)

            this.element.parentElement.prepend(actionsContainer)
        }
    }

    protected default_params(): Object {
        return TabulatorController.DEFAULT_PARAMS
    }

    protected default_options(): Object {
        return TabulatorController.DEFAULT_OPTIONS
    }
}