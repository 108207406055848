import 'src/turbo-rails'
import 'src/uikit'
import 'src/mwc'
import 'src/stimulus'
import Rails from "@rails/ujs"
Rails.start()
import 'src/custom-elements'

import moment from 'moment'
window.moment = moment

window.XLSX = require("xlsx")